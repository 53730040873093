export function objEmpty(obj){
  return !obj || !Object.keys(obj).length
}

export function updateURL(toDelete, toAddOrUpdate){
  const urlParams = new URLSearchParams(window.location.search);
  // delete unused params
  for (const param of toDelete) {
    urlParams.delete(param);
  }
  // add new params or update existing ones
  for (const [param, value] of Object.entries(toAddOrUpdate)) {
    urlParams.set(param, value);
  }
  window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
}

export function getURLFromDomain(domain) {
  let domainToUrlMap = {
    enterprise: {
      urlDomain: 'enterprise',
      participant_view: 'overview',
      domain_label: 'Enterprise'
    },
    ics: {
      urlDomain: 'ics',
      participant_view: 'overview',
      domain_label: 'ICS'
    },
    managed_services: {
      urlDomain: 'managed-services',
      participant_view: 'managed-services',
      domain_label: 'Managed Services'
    },
    deceptions: {
      urlDomain: 'deceptions',
      participant_view: 'deceptions',
      domain_label: 'Evaluations Trials: Deceptions'
    }
  };
  return domainToUrlMap[domain.toLowerCase()];
}

export function getDomainFromURL(url) {
  let urlToDomainMap = {
    enterprise: {
      domain: 'ENTERPRISE',
      participant_view: 'overview',
      domain_label: 'Enterprise'
    },
    ics: {
      domain: 'ICS',
      participant_view: 'overview',
      domain_label: 'ICS'
    },
    'managed-services': {
      domain: 'MANAGED_SERVICES',
      participant_view: 'managed-services',
      domain_label: 'Managed Services'
    },
    'trials-deceptions': {
      domain: 'DECEPTIONS',
      participant_view: 'deceptions',
      domain_label: 'Evaluations Trials: Deceptions'
    }
  };
  return urlToDomainMap[url.toLowerCase()]
}

export function getDatePublished(adversary, currentYear) {
  return adversary.date_published ? adversary.date_published.slice(0, 4) : currentYear;
}
