<template>
<div>

  <!-- lighthouse video and any text and buttons that are on the video -->
  <div class="video-text-container">
    <video
      :class="display.smAndDown.value ? 'lighthouse-video--mobile' : 'lighthouse-video'"
      id="video"
      autoplay
      muted
      loop
    >
      <source :src="`${$imageSrc}homepage-lighthouse.mp4`" type="video/mp4" >
    </video>
    <v-col
      :cols="display.smAndDown.value ? 12 : 5"
      :class="{
        'text-left c-text--white': true,
        'text-on-video': display.mdAndUp.value,
        'text-on-video--mobile': display.smAndDown.value
      }"
    >
      <!-- text on lighthous video -->
      <div class='mk-text--h1 mk-text--semibold'>Make informed security decisions</div>
      <div class="mk-text--h3 m-spacing--top-6">
        MITRE ATT&CK® Evaluations is your resource for understanding how security solution
        providers might protect your organization's unique needs against known adversaries
      </div>
      <!-- start using evaluations button -->
      <v-btn
        v-if="display.mdAndUp.value"
        :class="{
          'start-using-evaluations-button mk-button--primary-ghost': true,
          'm-spacing--top-6': display.mdAndUp.value,
          'start-using-evaluations-button--mobile': display.smAndDown.value
        }"
        :to="`/results/enterprise?view=cohort&evaluation=er6&result_type=DETECTION&scenarios=1,2,3`"
        pendo-id="home.evaluationtabs.startusingevaluationsbutton"
      >
        See latest evaluations
      </v-btn>
    </v-col>
    <!-- start using evaluations button is on the bottom of the lighthouse video if it is in mobile view -->
    <v-col class="start-using-evaluations-button--col">
      <v-btn v-if="display.smAndDown.value"
        class='mk-button--primary-ghost start-using-evaluations-button--mobile'
          :to="`/results/enterprise?view=cohort&evaluation=er6&result_type=DETECTION&scenarios=1,2,3`"
          pendo-id="home.evaluationtabs.startusingevaluationsbutton"
        >
          See latest evaluations
      </v-btn>
    </v-col>
    <!-- evaluation tabs -->
    <div class="evaluation-tabs">
      <v-row v-if="display.mdAndUp.value" id="evaluation-tabs">
        <v-col
          :class="{
            'evaluation-tab c-text--cursor-pointer': true,
            'evaluation-tab--active': selectedEvaluationTab.name === evaluation.name,
            'evaluation-tab--inactive': selectedEvaluationTab.name !== evaluation.name}"
          v-for="evaluation in displayedEvaluations"
          :value="evaluation.name"
          :key="evaluation.name"
          @click="selectEvaluation(evaluation)"
          data-id="home.evaluationtabs.evaluationtab"
        >
          <div class="m-spacing--left-9 m-spacing--top-7 m-spacing--bottom-5">
            <div class="mk-text--black text-left m-spacing--bottom-3 mk-text--h4 mk-text--semibold">
              {{evaluation.display_name}} ({{getYear(evaluation)}})
              <sup v-if="evaluation === displayedEvaluations[0]" class="c-text--primary mk-text--semibold mk-text--caption">NEW</sup>
            </div>
            <div class="mk-text--grey90 mk-text--body text-left">{{getDomainDisplayName(evaluation.domain)}}</div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>

  <!-- evaluation select for xs screen size -->
  <v-col class="col--more-x-padding" v-if="display.smAndDown.value">
    <VueMultiselect
      :options="displayedEvaluations"
      label="display_name"
      placeholder="Select evaluation"
      class="mk-vue-select"
      selectLabel=""
      deselectLabel=""
      selectedLabel=""
      v-model="selectedEvaluationTab"
      data-id="home.evaluationtabsmobile.evaluationselect"
      >
    </VueMultiselect>
  </v-col>

  <!-- information about selected evaluation -->
  <v-col v-if="selectedEvaluationTab" id="evaluation-description">
    <v-row class="col--more-x-padding">
      <v-col class="m-spacing--top-9" align="center" v-if="display.md.value">
        <EvaluationLogo :selectedEvaluationTab="selectedEvaluationTab" :getDomainURL="getDomainURL" />
      </v-col>
      <v-col class="text-left" :cols="display.mdAndDown.value ? 12 : 6">
        <div class="mk-text--h3 m-spacing--top-9">
          {{selectedEvaluationTab.display_name}} ({{getYear(selectedEvaluationTab)}}) <sup v-if="selectedEvaluationTab === displayedEvaluations[0]" class="c-text--primary">NEW</sup>
        </div>
        <div class="mk-text--grey60">{{getDomainDisplayName(selectedEvaluationTab.domain)}}</div>
        <p v-html="selectedEvaluationTab.description"></p>

        <v-row>
          <v-col :cols="display.mdAndDown.value ? 12 : 4" v-if="selectedEvaluationTab.date_published">
            <v-btn
              class="m-spacing--top-4 mk-button--primary-standard btn--full-col"
              width=180
              :to="selectedEvaluationTab.domain.toLowerCase() !== 'enterprise' && selectedEvaluationTab.domain.toLowerCase() !== 'managed_services' ?
                  `/${getDomainURL(selectedEvaluationTab.domain)}/participants/` :
                  `/results/${getDomainURL(selectedEvaluationTab.domain.toLowerCase())}/?scenario=1&evaluation=${selectedEvaluationTab.name}`"
              data-id="home.evaluationtabs.showresultsbutton"
            >
              Show results
            </v-btn>
          </v-col>
          <v-col :cols="display.mdAndDown.value ? 12 : 4">
            <v-btn
              data-id="home.evaluationtabs.learnmorelink"
              :class="{
                'mk-button--primary-link': selectedEvaluationTab.date_published,
                'btn--full-col': true,
                'mk-button--primary-ghost': !selectedEvaluationTab.date_published,
                'ml-1  m-spacing--top-4': display.lgAndUp.value
              }"
              elevation="0"
              :to="`/${getDomainURL(selectedEvaluationTab.domain)}/${selectedEvaluationTab.name}/`"
                >Learn more</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!-- evaluation logo -->
      <v-col class="m-spacing--top-9 evaluation-logo-col" align="right" v-if="display.lgAndUp.value">
        <EvaluationLogo :selectedEvaluationTab="selectedEvaluationTab" :getDomainURL="getDomainURL" />
      </v-col>
    </v-row>
    <v-divider class="m-spacing--top-9"></v-divider>
    <!-- participant logo slide -->
    <v-row>
      <v-col :class="{'slide-group-col': true, 'col--more-x-padding': display.mdAndUp.value}" v-if="display.smAndUp.value">
        <v-slide-group class="participant-slide-group" show-arrows="always">
          <template v-slot:next>
            <v-icon
              color="primary"
              size="x-large"
              icon="mdi-chevron-right"
              data-id="home.evaluationtabs.slidegroup.nextbutton"
              >
            </v-icon>
          </template>
          <template v-slot:prev>
            <v-icon
              color="primary"
              size="x-large"
              icon="mdi-chevron-left"
              data-id="home.evaluationtabs.slidegroup.previousbutton"
              >
            </v-icon>
          </template>
          <v-row justify="center" align="center">
            <v-slide-group-item
              v-for="p in selectedEvaluationTab.participants"
              :key="p.participant.name"
            >
              <v-img
                :class="{
                  'ma-3': display.mdAndUp.value,
                  'pa-8 c-text--cursor-pointer': true,
                  'disabled': !selectedEvaluationTab.date_published
                }"
                :height="130"
                :width="170"
                contain
                eager
                :src="$imageSrc + p.participant.logo_image"
                @click="routeToParticipant(p)"
                data-id="home.evaluationtabs.participantlogolink"
              ></v-img>
            </v-slide-group-item>
          </v-row>
        </v-slide-group>
      </v-col>
    </v-row>
  </v-col>

</div>
</template>

<script setup>
import { getURLFromDomain, getParticipantResultsRoute } from "../../mixins";
import VueMultiselect from "vue-multiselect";
import { useRouter } from 'vue-router';
import EvaluationLogo from './EvaluationLogo.vue';
import { ref, watch, computed, onMounted, onUnmounted, inject } from "vue";
import { useDisplay } from 'vuetify'

const globalProperties = inject('globalProperties');

const selectedEvaluationTab = ref(undefined);
const evaluations = ref([]);
const currentYear = new Date().getFullYear();
const router = useRouter();
const displayedEvaluations = ref([]);
const display = useDisplay();

watch(selectedEvaluationTab, () => {
  if (selectedEvaluationTab.value.name === 'turla'){
    addNoResultsParticipantsTurla();
  }
  else if(selectedEvaluationTab.value.name === 'oilrig'){
    addNoResultsParticipantsOilrig();
  }
})

const linkView = computed(() => {
  if (selectedEvaluationTab.value.domain.toLowerCase() === "deceptions") {
      return "?view=deceptions";
    }
    else {
      return selectedEvaluationTab.value.domain.toLowerCase() === "managed_services" ? "?view=managed-services" : "?view=overview";
    }
})

function routeToParticipant(participant) {
  const selectedEvalTabDomain = selectedEvaluationTab.value.domain.toLowerCase();
  let route = "";
  if(selectedEvalTabDomain !== 'enterprise' && selectedEvalTabDomain !== 'managed_services') {
    route = `/${getDomainURL(selectedEvalTabDomain)}/participants/${participant.participant.name}${linkView.value}`;
  }
  else {
    route = getParticipantResultsRoute(selectedEvaluationTab.value, participant);
  }

  router.push(route);
}

function selectEvaluation(evaluation) {
  selectedEvaluationTab.value = evaluation;
  let evaluationDescription = document.getElementById('evaluation-description');
  let bottomEvaluationDescription = evaluationDescription.getBoundingClientRect().bottom;
  if (bottomEvaluationDescription > window.innerHeight){
    let scrollToElement = evaluationDescription;
    let headerHeight = 100;
    let evaluationTabHeight = 100;
    let topOfElement = scrollToElement.getBoundingClientRect().top + window.scrollY - headerHeight - evaluationTabHeight;
    window.scrollTo({top: topOfElement, behavior: 'smooth'});
  }
}

function addNoResultsParticipantsOilrig(){
  if(selectedEvaluationTab.value.participants.find(p => p.participant.name === 'trendmicro') === undefined) {
    globalProperties.$api
      .get(`participants/?name=trendmicro`).then(response => {
        let trendmicro = {};
        trendmicro['participant'] = response.data[0]
        selectedEvaluationTab.value.participants.push(trendmicro)
        selectedEvaluationTab.value.participants = selectedEvaluationTab.value.participants.sort(function(a,b) {
          return (a['participant'].name > b['participant'].name ? 1 : -1)
        });
      })
  }
}

function addNoResultsParticipantsTurla(){
  if(selectedEvaluationTab.value.participants.find(p => p.participant.name === 'cisco') === undefined) {
    globalProperties.$api
      .get(`participants/?name=cisco`).then(response => {
        let cisco = {};
        cisco['participant'] = response.data[0];
        selectedEvaluationTab.value.participants.push(cisco);
        selectedEvaluationTab.value.participants = selectedEvaluationTab.value.participants.sort(function(a,b) {
          return (a['participant'].name > b['participant'].name ? 1 : -1)
        });
      })
  }
  if(selectedEvaluationTab.value.participants.find(p => p.participant.name === 'checkpoint') === undefined) {
    globalProperties.$api
      .get(`participants/?name=checkpoint`).then(response => {
        let checkpoint = {};
        checkpoint['participant'] = response.data[0];
        selectedEvaluationTab.value.participants.push(checkpoint);
        selectedEvaluationTab.value.participants = selectedEvaluationTab.value.participants.sort(function(a,b) {
          return (a['participant'].name > b['participant'].name ? 1 : -1)
        });
      })
  }
}

function getDomainDisplayName(domain){
  return getURLFromDomain(domain).domain_label;
}

function getDomainURL(domain){
  return getURLFromDomain(domain).urlDomain;
}

function getYear(evaluation){
  return evaluation.date_published ? evaluation.date_published.slice(0, 4) : currentYear;
}

function getEvaluations(){
  globalProperties.$api.get("adversaries/").then(response => {
    // remove or statement before release
    let evaluations = response.data.filter(evaluation => evaluation.status === "PUBLISHED" && evaluation.date_published);
    evaluations = evaluations.sort((a,b) => {
      let aDatePublished = new Date(a.date_published);
      let bDatePublished = new Date(b.date_published);
      return bDatePublished - aDatePublished
    })
    evaluations.value = evaluations;
    displayedEvaluations.value = display.md.value ? evaluations.slice(0,2) : evaluations.slice(0, 3);
    selectedEvaluationTab.value = evaluations[0];
  })
}

function handlePageResize(){
  displayedEvaluations.value = display.md.value ? evaluations.value.slice(0,2) : evaluations.value.slice(0, 3);
}

onMounted(() => {
  getEvaluations();
  console.log(evaluations)
  window.addEventListener("resize", handlePageResize);
})

onUnmounted(() => {
  window.removeEventListener("resize", handlePageResize)
})
</script>

<style lang="scss" scoped>
@import "../../sass/pages/_evaluation-tabs.scss";
</style>
