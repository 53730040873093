<template>
  <v-app>
    <div id="app">
      <Navigation />
      <div :class="$vuetify.display.mobile ? 'app-body--mobile' : 'app-body'">
        <template v-if="showBanner && route.name === 'Home'">
          <v-slide-x-reverse-transition>
            <v-alert class="mk-toast-notification--banner">
              Now accepting participant applications for our MSR3 evaluation.
              <a class="ml-5 c-text--white" rel="noopener" target="_blank" href="/managed-services/msr3/">
                <v-icon>mdi-arrow-right</v-icon>
              </a>
            </v-alert>
          </v-slide-x-reverse-transition>
        </template>
        <BreadCrumb />
        <router-view></router-view>
      </div>
      <PageFooter v-if="$route.name !== 'EnterpriseResults'"/>
    </div>
  </v-app>
</template>

<script>
import BreadCrumb from './components/base/BreadCrumb.vue';
import PageFooter from './components/base/PageFooter.vue';
import Navigation from './components/base/Navigation.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  name: 'App',
  components: {
    BreadCrumb,
    PageFooter,
    Navigation,
  },
  data(){
    return {
      route: useRoute(),
      store: useStore(),
    }
  },
  computed: {
    showBanner() {
      return false;
    },
  },
  methods: {
    getAllAdversaries() {
      this.$api.get(`adversaries/`).then((response) => {
        if (!response.data.length) this.$handleNotFound();

        this.store.commit(
          'setAllAdversaries',
          response.data.reverse(),
        );
      });
    },
    getAllParticipants() {
      this.$api.get(`participants/`).then((response) => {
        if (!response.data.length) this.$handleNotFound();
        this.store.commit('setAllParticipants', response.data.reverse());
      });
    },
  },
  mounted() {
    this.getAllAdversaries();
    this.getAllParticipants();
    let pendoData = {}
    let cookies = document.cookie.split("; ");
    let cookieNamesAndValues = {};
    cookies.forEach(cookie =>{
      let cookieName = cookie.split("=")[0];
      let cookieValue = cookie.split("=")[1];
      cookieNamesAndValues[cookieValue] = cookieName;
    });
    if (Object.keys(cookieNamesAndValues).includes('participant_id')){
      let participantId = cookieNamesAndValues.participant_id;
      pendoData.account ? pendoData.account.participant_id = participantId : pendoData.account = {participant_id: participantId};
    }
    if (Object.keys(cookieNamesAndValues).includes('participant_name')){
      let participantName = cookieNamesAndValues.participant_name;
      pendoData.account ? pendoData.account.participant_name = participantName : pendoData.account = {participant_name: participantName};
    }
    if (Object.keys(cookieNamesAndValues).includes('user_id')){
      let userId = cookieNamesAndValues.user_id;
      pendoData.visitor = userId;
    }
    window.pendo.initialize(pendoData);
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        console.error("ResizeObserver loop limit exceeded")
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
