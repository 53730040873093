<template>
  <div v-if="showCard">
    <v-card
      v-if="display.smAndDown.value"
      color="primary"
    >
      <v-card-text class="mk-text--body text-left mt-2">
        <p>
          <b>Enterprise 2025: Call for Participation</b>
        </p>
        <p>
          MITRE is excited to open its Call for Participation for ATT&CK® Evaluations Enterprise 2025!
        </p>
        <p>
          The seventh round of Enterprise evaluations, designed for security solutions deployed to enterprise
          environments, will focus on detection of and protection against cloud-based attacks and abuse of legitimate
          tools and processes.
        </p>
        <v-btn
          width=100%
          class="mk-button--primary-standard mt-4"
          color="background"
          @click="getEvaluatedExternal()"
        >
          Participate
        </v-btn>
      </v-card-text>
    </v-card>
    <div v-else class="cfp-card">
      <div class="mb-4 cfp-btn-div">
        <v-btn
          color="background"
          icon="mdi-close"
          size="small"
          @click="closeCard()"
        />
      </div>
      <v-card
        color="background"
      >
        <v-card-title>
          <v-card
            color="background"
            border="sm"
            flat
          >
            <v-img
              class="ma-auto"
              :src="$imageSrc + adversary.logo_image"
              max-width="250"
              width="100%"
              contain
            />
            <div>
              <span class="mk-text--h2">{{ adversary.display_name }}</span>
            </div>
            <span class="c-text--primary mk-text--body">Call for Participation</span>
          </v-card>
        </v-card-title>
        <v-card-text class="mk-text--body text-left mt-4">
          <p>
            MITRE is excited to open its Call for Participation for ATT&CK® Evaluations Enterprise 2025!
          </p>
          <p>
            The seventh round of Enterprise evaluations, designed for security solutions deployed to enterprise
            environments, will focus on detection of and protection against cloud-based attacks and abuse of legitimate
            tools and processes.
          </p>
          <v-btn
            width=100%
            class="mt-4"
            color="primary"
            @click="getEvaluatedExternal()"
          >
            Participate
          </v-btn>
          <v-btn
            width=100%
            class="mk-button--primary-link mt-4"
            color="background"
            flat
            :to="`/enterprise/${adversary.name}/`"
          >
            Learn More
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useDisplay } from 'vuetify';

  const adversary = ref([]);
  const display = useDisplay();
  const showCard = ref(true);
  const store = useStore();

  const allAdversaries = computed(() => store.getters.allAdversaries);

  function closeCard() {
    showCard.value = !showCard.value;
  }

  function getEvaluatedExternal() {
    window.open("https://bit.ly/er7-commit", "_blank", "noopener");
  }

  watch(allAdversaries, (newVal) => {
  if (newVal && Object.keys(newVal).length !== 0) {
    adversary.value = allAdversaries.value.find(adv => adv.status === "CALL_FOR_PARTICIPATION");
  }
}, { immediate: true });
</script>

<style lang="scss" scoped>
  @import "../../sass/pages/_cfp-alert.scss";
</style>