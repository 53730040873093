<template>
<div>
  <v-footer class="top-footer" id="footer">
    <v-row v-if="!$vuetify.display.mobile" no-gutters class="text-left mx-10">
      <v-col
        v-for="(value, key) in footerColumns"
        :key="value"
        class="mx-5"
      >
        <div class="c-text--bold my-4">
          {{ key }}
        </div>
        <div
          v-for="(value, key) in value.sublinks"
          :key="key"
          class="c-text--primary my-4"
          >
          <a
            class="c-text--hover-no-underline"
            :href="value.link"
            :target="value.target"
            rel="noopener noreferrer"
            >
            {{key}}
          </a>
        </div>
      </v-col>
    </v-row>
    <v-col v-else>
      <div
          v-for="(value, key) in footerColumns"
          :key="value"
          class="mx-5 text-left"
          >
          <div class="c-text--bold my-4">
            {{ key }}
          </div>
          <div
            v-for="(value, key) in value.sublinks"
            :key="key"
            class="c-text--primary my-4"
            >
            <a
              :href="value.link"
              :target="value.target"
              rel="noopener noreferrer"
              >
              {{key}}
            </a>
          </div>
        </div>
    </v-col>
  </v-footer>
  <v-footer class="bottom-footer text-left mx-10">
    <v-row v-if="!$vuetify.display.mobile" class="mx-1">
      <v-col cols="10">
        <v-row>
          <div
          class="bottom-links"
          v-for="(value, key) in bottomLinks"
          :key="value"
          >
            <div>
              <a
                class="c-text--hover-no-underline"
                :href="value.link"
                :target="value.target"
                rel="noopener noreferrer"
                >
                {{key}}
              </a>
            </div>
          </div>
          <div class="bottom-links">
            <a @click="showOsanoDrawer" class="c-text--hover-no-underline">Cookie Preferences</a>
          </div>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-row>
          <div
            v-for="(value, key) in iconLinks"
            :key="key"
            class="icon-links"
            >
            <div>
              <a
                class="c-text--hover-no-underline"
                :href="value.link"
                :target="value.target"
                rel="noopener noreferrer"
              >
                <v-icon>mdi-{{value.icon}}</v-icon>
              </a>
            </div>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-row class="mt-2">
        <div
        class="bottom-links--mobile"
        v-for="(value, key) in bottomLinks"
        :key="value"
        >
          <div>
            <a
              :href="value.link"
              :target="value.target"
              rel="noopener noreferrer"
              >
              {{key}}
            </a>
          </div>
        </div>
      </v-row>
      <v-row class="mt-2">
        <div
          v-for="(value, key) in iconLinks"
          :key="key"
          class="icon-links--mobile"
          >
          <div>
            <a
              :href="value.link"
              :target="value.target"
              rel="noopener noreferrer"
            >
              <v-icon>mdi-{{value.icon}}</v-icon>
            </a>
          </div>
        </div>
      </v-row>
    </v-row>
  </v-footer>
</div>
</template>

<script>
export default {
  name: "PageFooter",
  data: () => ({
    footerColumns: {
        'Enterprise': {
          "sublinks": {
            "Enterprise (2024)": {
              "link": "/enterprise/er6",
              "target": ""
            },
            "Turla (2023)": {
              "link": "/enterprise/turla",
              "target": ""
            },
            "Wizard Spider & Sandworm (2022)": {
              "link": "/enterprise/wizard-spider-sandworm",
              "target": ""
            },
            "Carbanak & FIN7 (2021)": {
              "link": "/enterprise/carbanak-fin7",
              "target": ""
            },
            "APT 29 (2019)":{
              "link": "/enterprise/apt29",
              "target": ""
            },
            "APT 3 (2018)": {
              "link": "/enterprise/apt3",
              "target": ""
            },
          },
        }
        ,
        'Managed Services': {
          "sublinks": {
            "menuPass & ALPHV BlackCat (2024)": {
              "link": "/managed-services/menupass-blackcat",
              "target": ""
            },
            "OilRig (2022)": {
              "link": "/managed-services/oilrig",
              "target": ""
            },
          },
        },
        'Industrial Control Systems': {
          "sublinks": {
            "Triton (2021)": {
              "link": "/ics/triton",
              "target": ""
            },
          },
        },
        'Trials': {
          "sublinks": {
            "APT 29 Deceptions (2022)": {
              "link": "/trials-deceptions/apt29-deceptions",
              "target": ""
            },
          },
        },
        'Resources': {
          "sublinks": {
            "About": {
              "link": "/about/",
              "target": ""
            },
            "Methodology": {
              "link": "/methodology-overview/",
              "target": "",
            },
            "Blog": {
              "link": "https://medium.com/mitre-engenuity/tagged/evaluation",
              "target": "_blank",
            },
            "FAQ": {
              "link": "/FAQ/",
              "target": "",
            },
            "Emulation plan library": {
              "link": "https://github.com/attackevals/ael",
              "target": "_blank",
            },
          },
        },
    },
    bottomLinks: {
      "MITRE": {
        "link": "https://www.mitre.org/",
        "target": "_blank"
      },
      "MITRE ATT&CK®": {
        "link": "https://attack.mitre.org/",
        "target": "_blank"
      },
      "Terms & Conditions": {
        "link": "/terms-of-use",
        "target": ""
      },
      "Privacy Policy": {
        "link": "https://www.mitre.org/privacy-policy",
        "target": "_blank"
      },
      "Citation Policy": {
        "link": "/citation-policy",
        "target": ""
      },
    },
    iconLinks: {
      "x": {
        "icon" : "twitter",
        "link": "https://x.com/MITRE",
        "target": "_blank"
      },
      "linkedin": {
        "icon" : "linkedin",
        "link": "https://www.linkedin.com/showcase/attack-evaluations/",
        "target": "_blank"
      },
    },
  }),
  methods: {
    showOsanoDrawer(){
      window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
    }
  }
};
</script>

<style scoped lang="scss">
  @import "../../sass/pages/_footer.scss";
</style>
