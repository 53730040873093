import { createWebHistory, createRouter } from "vue-router";
import { getDomainFromURL } from "../mixins.js";

const ContactUs = () => import("../components/ContactUs.vue");
const GetUpdates = () => import("../components/GetUpdates.vue");
const Credits = () => import("../components/Credits.vue");
const Faq = () => import("../components/Faq.vue");
const GetEvaluated = () => import("../components/GetEvaluated.vue");

import Home from "../components/home/Home.vue";

const MethodologyOverview = () => import("../components/MethodologyOverview.vue");
const NotFound = () => import("../components/NotFound.vue");
const ParticipantComparison = () => import("../components/tools/ParticipantComparison.vue");
const CitationPolicy = () => import("../components/CitationPolicy.vue");
const EnterpriseResults = () => import("../components/results/EnterpriseResults.vue");
const TechniqueComparison = () => import("../components/tools/TechniqueComparison.vue");
const TermsOfUse = () => import("../components/TermsOfUse.vue");
const about = () => import("../components/About.vue");
const ViewAllEvaluations = () => import('../components/ViewAllEvaluations.vue');
const ViewAllParticipants = () => import("../components/ViewAllParticipants.vue");

const AdversaryOverview = () => import("../components/adversaries/AdversaryOverview.vue");

const LearnMore = () => import("../components/database-templates/LearnMore.vue");
const ParticipantConfiguration = () => import("../components/database-templates/ParticipantConfiguration.vue");

const AttackPatternSummary = () => import("../components/participant-summary/AttackPatternSummary.vue");
const ParticipantSummary = () => import("../components/participant-summary/ParticipantSummary.vue");

const AdversaryRouter = () => import("../components/routers/AdversaryRouter.vue");
const EvaluationsRouter = () => import('../components/routers/EvaluationsRouter.vue');
const ParticipantSummaryRouter = () => import("../components/routers/ParticipantSummaryRouter.vue");
const ParticipantsRouter = () => import("../components/routers/ParticipantsRouter.vue");
const ResultsParticipantRouter = () => import("../components/routers/ResultsParticipantRouter.vue");
const ResultsRouter = () => import("../components/routers/ResultsRouter.vue");

export const appUUID = (new Date()).getTime().toString(36) + Math.random().toString(36).slice(2);

const routes = [
    {
        path: "/contact-us/",
        name: "ContactUs",
        component: ContactUs,
        meta: {breadcrumb: "Contact Us"},
    },
    {
        path: `/credits-${appUUID}`,
        name: "Credits",
        component: Credits,
        meta: {breadcrumb: "Credits"},
    },
    {
        path: "/FAQ/",
        name: "FAQ",
        component: Faq,
        meta: {breadcrumb: "FAQ"},
    },
    {
        path: "/get-evaluated/",
        name: "Get Evaluated",
        component: GetEvaluated,
        meta: {breadcrumb: "Get Evaluated"},
    },
    {
       path: "/get-updates/",
       name: "Get Updates",
       component: GetUpdates,
       meta: {breadcrumb: "Get Updates"}
    },
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {breadcrumb: "Home"},
    },
    {
        path: "/index:afterIndex(.*)",
        redirect: "/",
    },
    {
        path: "/methodology-overview/",
        name: "MethodologyOverview",
        component: MethodologyOverview,
        meta: {breadcrumb: "Methodology Overview"},
    },
    {
        path: "/citation-policy/",
        name: "CitationPolicy",
        component: CitationPolicy,
        meta: {breadcrumb: "Citation Policy"},
    },
    {
        path: "/terms-of-use/",
        name: "TermsOfUse",
        component: TermsOfUse,
        meta: {breadcrumb: "Terms of Use"},
    },
    {
        path: "/about/",
        name: "About",
        component: about,
        meta: {breadcrumb: "About"},
    },
    {
        path: "/enterprise/wizard-spider-and-sandworm",
        redirect: "/enterprise/wizard-spider-sandworm",
    },
    {
        path: "/results/:domain",
        component: ResultsRouter,
        meta: {breadcrumb: "Results"},
        beforeEnter: (_to, _from, next) => {
          if(_to.params.domain.toLowerCase() === 'enterprise' || _to.params.domain.toLowerCase() === 'managed-services'){
            next()
          }
          else if(getDomainFromURL(_to.params.domain)){
            next(`/${_to.params.domain}/participants/`)
          }
          else{
            next('/404')
          }
        },
        children: [
          {
            path: "",
            component: EnterpriseResults,
            name: "EnterpriseResults",
            meta: {breadcrumb: ":domain"},
          },
          {
            path: ":participantName",
            component: ResultsParticipantRouter,
            meta: {breadcrumb: ":participantName"},
            redirect: "/results/enterprise/",
            children: [
              {
                path: ":configuration",
                name: ":configuration",
                component: ParticipantConfiguration,
                meta: {breadcrumb: ":configuration"}
              }
            ]
          }
        ]
    },
    {
        path: "/404",
        component: NotFound,
        name: "NotFound",
        meta: {breadcrumb: "404 Not Found"}
    },
    {
        path: "/:domain",
        component: EvaluationsRouter,
        meta: {breadcrumb: ":domain"},
        beforeEnter: (_to, _from, next) => {
          if(getDomainFromURL(_to.params.domain)){
            next()
          }
          else{
            next('/404')
          }
        },
        children: [
            {
                path: "",
                component: ViewAllEvaluations,
                name: "ViewAllEvaluations",
                meta: {breadcrumb: "View All Evaluations"},
                beforeEnter: (_to, _from, next) => {
                  if(_to.params.domain.toLowerCase() === 'enterprise'){
                    next("/results/enterprise/")
                  }
                  else if(getDomainFromURL(_to.params.domain)){
                    next()
                  }
                  else{
                    next('/404')
                  }
                }
            },
            {
                path: "participants",
                component: ParticipantsRouter,
                meta: {breadcrumb: "Participants"},
                beforeEnter: (_to, _from, next) => {
                    if(_to.params.domain.toLowerCase() === 'enterprise' || _to.params.domain.toLowerCase() === 'managed-services'){
                      next(`/results/${_to.params.domain}/`)
                    }
                    else if(getDomainFromURL(_to.params.domain)){
                      next()
                    }
                    else{
                      next('/404')
                    }
                },
                children: [
                    {
                        path: "",
                        name: "ViewAllParticipants",
                        component: ViewAllParticipants,
                    },
                    {
                        path: ":participantName",
                        component: ParticipantSummaryRouter,
                        meta: {breadcrumb: ":participantName"},
                        children: [
                            {
                                path: "",
                                name: "ParticipantSummary",
                                component: ParticipantSummary,
                            },
                            {
                                path: ":configuration",
                                name: "ParticipantConfiguration",
                                component: ParticipantConfiguration,
                                meta: {breadcrumb: ":configuration"},

                            },
                            {
                                path: "tactic/:tid",
                                name: "TacticSummary",
                                component: AttackPatternSummary,
                                meta: {breadcrumb: "Tactic"}
                            },
                            {
                                path: "technique/:tid",
                                name: "TechniqueSummary",
                                component: AttackPatternSummary,
                                meta: {breadcrumb: "Technique"}
                            },
                            {
                                path: "subtechnique/:tid",
                                name: "SubtechniqueSummary",
                                component: AttackPatternSummary,
                                meta: {breadcrumb: "Subtechnique"}
                            },
                        ]
                    },

                ]
            },
            {
                path: "participant-comparison",
                name: "ParticipantComparison",
                component: ParticipantComparison,
                meta: {breadcrumb: "Participant Comparison"},
                beforeEnter: (_to, _from, next) => {
                  if(_to.params.domain.toLowerCase() === 'enterprise'){
                    next("/results/enterprise/")
                  }
                  else if(getDomainFromURL(_to.params.domain) && _to.params.domain.toLowerCase() !== 'ics'){
                    next(`/${_to.params.domain.toLowerCase()}/participants/`)
                  }
                  else if(_to.params.domain.toLowerCase() === 'ics') {
                    next()
                  }
                  else{
                    next('/404')
                  }
                }
            },
            {
                path: "technique-comparison",
                name: "TechniqueComparison",
                component: TechniqueComparison,
                meta: {breadcrumb: "Technique Comparison"},
                beforeEnter: (_to, _from, next) => {
                  if(_to.params.domain.toLowerCase() === 'enterprise'){
                    next("/results/enterprise/")
                  }
                  else if(getDomainFromURL(_to.params.domain) && _to.params.domain.toLowerCase() !== 'ics'){
                    next(`/${_to.params.domain.toLowerCase()}/participants/`)
                  }
                  else if(_to.params.domain.toLowerCase() === 'ics') {
                    next()
                  }
                  else{
                    next('/404')
                  }
                }
            },
            {
                path: ":adversary",
                component: AdversaryRouter,
                meta: {breadcrumb: ":adversary"},
                children: [
                    {
                        path: "",
                        name: "AdversaryOverview",
                        component: AdversaryOverview,
                        beforeEnter: (_to, _from, next) => {
                          if (_to.params.adversary === 'managed-services') {
                              _to.params.adversary = 'oilrig';
                          }
                          next();
                        }
                    },
                    {
                        path: ":templateType",
                        name: "LearnMore",
                        component: LearnMore,
                        meta: {breadcrumb: ":templateType"}
                    }
                ]
            },
        ]
    },
];

const router = createRouter({
    mode: "history",
    base: "/",
    routes,
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
      let position = {};
      if (savedPosition) {
        position = savedPosition;
      }
      else if (to.hash) {
        position = {
          selector: to.hash,
          el: to.hash,
          behavior: 'smooth'
        }
      }
      else {
        position = {
          left: 0,
          top: 0
        };
      }
      return new Promise((resolve) => {
      setTimeout(() => {
        resolve(position)
      }, 500)
    })
    }
});

export default router;
