<template>
  <div>
    <v-app-bar color="background" class="nav-bar" id="header" width="100%" :height="$vuetify.display.mobile ? '70' : '100'">
      <v-app-bar-title class="mr-auto mt-3">
        <router-link to="/" :class="$vuetify.display.mobile ? 'router-link--mobile' : 'router-link'">
          <v-img
            :src="
              require(`../../../assets/images/MITRE_Engenuity_ATTACK-Evaluations_logo_PMS_purple.svg`)
            "
          />
        </router-link>
      </v-app-bar-title>
      <v-app-bar-nav-icon v-if="$vuetify.display.mobile" @click="drawer = true"></v-app-bar-nav-icon>
      <template v-for="nav_item in nav_items" :key="nav_item.title">
        <v-menu
          open-on-hover
          offset-y
          :key="nav_item.title"
          v-if="nav_item.dropdown_items && !nav_item.drawer_only && !$vuetify.display.mobile"
          :close-on-content-click="false"
          v-model="nav_item.menu"
        >
          <template v-slot:activator="{ props }" v-if="!$vuetify.display.mobile">
              <v-list-item-title v-bind="props" class="list__title"
                >{{ nav_item.title }}
                <v-icon medium> mdi-menu-down</v-icon>
              </v-list-item-title>
          </template>

          <v-list flat class="list">
            <template v-for="dropdown_item in nav_item.dropdown_items">
              <v-list-group
                v-if="dropdown_item.submenu_items"
                :key="dropdown_item.title"
                color="primary"
              >
                  <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" :title="dropdown_item.title" exact>
                    </v-list-item>
                  </template>

                  <v-list-item
                    v-for="subItem in dropdown_item.submenu_items"
                    :key="subItem.title"
                    :to="subItem.route"
                    :title="subItem.title"
                    exact
                    @click="nav_item.menu=false"
                  >
                  </v-list-item>
                </v-list-group>
              <v-list-item
                v-else-if="dropdown_item.external"
                :key="dropdown_item.title"
                :href="dropdown_item.external"
                target="_blank"
                dense
                exact
                @click="nav_item.menu=false"
              >
                <v-list-item-title>
                  {{ dropdown_item.title }}
                  <v-icon small> mdi-open-in-new</v-icon>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-else
                :key="dropdown_item.title"
                :to="dropdown_item.route"
                :title="dropdown_item.title"
                @click="nav_item.menu=false"
                exact
              >
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

        <v-btn
          v-else-if="!nav_item.drawer_only && !$vuetify.display.mobile"
          :href="nav_item.external && nav_item.external"
          :to="nav_item.route && nav_item.route"
          :key="nav_item.title"
          :class="nav_item.class"
          :data-id="nav_item.data_id ? nav_item.data_id : ''"
        >
          {{ nav_item.title }}
        </v-btn>
      </template>
    </v-app-bar>

    <v-navigation-drawer v-if="$vuetify.display.mobile" v-model="drawer" color="background" location="right">
      <v-list nav flat>
        <template v-for="nav_item in nav_items">
          <template v-if="!nav_item.dropdown_items">
            <v-list-item
              v-if="nav_item.external"
              :key="nav_item.title"
              :href="nav_item.external"
              :title="nav_item.title"
              exact
            >
            </v-list-item>
            <v-list-item
              v-else
              :key="nav_item.title"
              :to="nav_item.route"
              link
              :title="nav_item.title"
              exact
            >
            </v-list-item>
          </template>
          <v-list-group v-else :key="nav_item.title" :title="nav_item.title" color="primary">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" class="text-wrap" :title="nav_item.title"></v-list-item>
            </template>
            <template v-for="dropdown_item in nav_item.dropdown_items">
              <v-list-group v-if="dropdown_item.submenu_items" :key="dropdown_item.title" :title="dropdown_item.title" color="primary">
                <template v-slot:activator="{ props }">
                  <v-list-item v-bind="props" class="text-wrap" :title="dropdown_item.title" exact></v-list-item>
                </template>
                <template v-for="submenu_item in dropdown_item.submenu_items" :key="submenu_item.title">
                  <v-list-item :title="submenu_item.title" class="pl-0" link :to="submenu_item.route" exact>
                  </v-list-item>
                </template>
              </v-list-group>
              <v-list-item
                v-else-if="dropdown_item.external"
                :key="dropdown_item.title"
                :href="dropdown_item.external"
                target="_blank"
                exact
                class="list__item"
              >
                <v-list-item-title>
                  {{dropdown_item.title}}
                  <v-icon size="x-small">mdi-open-in-new</v-icon>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-else
                :key="dropdown_item.title"
                :to="dropdown_item.route"
                link
                :title="dropdown_item.title"
                exact
              >
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { mapGetters } from 'vuex';
export default {
  name: 'Navigation',
  mostRecentResultsEnterpriseEvaluation: undefined,
  data: () => ({
    drawer: false,
    group: null,
    route: useRoute(),
    nav_items: [
      {
        title: 'Home',
        route: '/',
        icon: 'mdi-home',
        drawer_only: true,
        menu: false,
      },
      {
        title: 'Results',
        icon: 'mdi-domain',
        drawer_only: false,
        menu: false,
        class: 'nav-item--button',
        dropdown_items: [
          {
            title: 'Enterprise',
            route: `/results/enterprise/`,
          },
          { title: 'Managed Services',
            route: `/results/managed-services/`
          },
          {
            title: 'ICS (Triton)',
            route: '/',
            submenu_items: [
              { title: 'Results', route: '/ics/participants/' },
              { title: 'Overview', route: '/ics/triton/' },
            ],
          },
          {
            title: 'Trials',
            icon: 'mdi-domain',
            route: '/',
            submenu_items: [
              { title: 'Results', route: '/trials-deceptions/participants/' },
              { title: 'Overview', route: '/trials-deceptions/apt29-deceptions/' },
              ],
          },
        ],
      },
      {
        title: 'Resources',
        icon: 'mdi-folder',
        drawer_only: false,
        menu: false,
        dropdown_items: [
          { 
            title: 'Methodology', 
            route: '/methodology-overview/' 
          },
          {
            title: 'About',
            route: '/about/',
          },
          {
            title: 'Blog',
            external: 'https://medium.com/mitre-engenuity/tagged/evaluation',
          },
          { title: 'FAQ', route: '/FAQ/' },
          {
            title: 'Emulation Plan Library',
            external:
              'https://github.com/attackevals/ael',
          },
          {
            title: 'MITRE ATT&CK Defender Training',
            external: 'https://mitre-engenuity.org/mad/',
          },
          {
            title: 'Center for Threat-Informed Defense',
            external: 'https://mitre-engenuity.org/ctid/',
          },
        ],
      },
      {
        title: 'Get Evaluated',
        external: 'https://mitre-engenuity.org/cybersecurity/attack-evaluations/#get-evaluated',
        icon: 'mdi-clipboard-text',
        drawer_only: false,
        menu: false,
        class: ['mk-button--primary-ghost', 'ml-7'],
        data_id: 'mainnav.getevaluated.button'
      },
      {
        title: 'Contact Us',
        route: '/contact-us/',
        icon: 'mdi-card-account-mail',
        drawer_only: true,
        menu: false,
      },
      {
        title: 'Terms of Use',
        route: '/terms-of-use/',
        icon: 'mdi-clipboard-edit',
        drawer_only: true,
        menu: false,
      },
      {
        title: 'Privacy Policy',
        route: '/privacy-policy/',
        icon: 'mdi-file-lock',
        drawer_only: true,
        menu: false,
      },
    ],
  }),
  computed: {
    ...mapGetters(['allAdversaries'])
  },
  watch: {
    allAdversaries: {
      handler (allAdversaries) {
        if (allAdversaries && Object.keys(allAdversaries).length !== 0) {
          let enterprisePublishedEvaluations = this.allAdversaries.filter(adv => adv.domain === 'ENTERPRISE' && adv.status === "PUBLISHED").sort(
            (a,b) =>  a.eval_round - b.eval_round ? -1 : 1
          );
          this.nav_items.find(item => item.title === 'Results').dropdown_items.find(item => item.title === 'Enterprise').route = `/results/enterprise/?evaluation=${enterprisePublishedEvaluations[0].name}&scenario=1`
          let managedServicesPublishedEvaluations = this.allAdversaries.filter(adv => adv.domain === 'MANAGED_SERVICES' && adv.status === "PUBLISHED").sort(
            (a,b) =>  a.eval_round - b.eval_round ? -1 : 1
          );
          this.nav_items.find(item => item.title === 'Results').dropdown_items.find(item => item.title === 'Managed Services').route = `/results/managed-services/?evaluation=${managedServicesPublishedEvaluations[0].name}&scenario=1`
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="scss">
  @import "../../sass/pages/_navigation.scss";
</style>
