export function objEmpty(obj){
  return !obj || !Object.keys(obj).length
}

export function updateURL(toDelete, toAddOrUpdate){
  const urlParams = new URLSearchParams(window.location.search);
  // delete unused params
  for (const param of toDelete) {
    urlParams.delete(param);
  }
  // add new params or update existing ones
  for (const [param, value] of Object.entries(toAddOrUpdate)) {
    urlParams.set(param, value);
  }
  window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
}

export function getURLFromDomain(domain) {
  let domainToUrlMap = {
    enterprise: {
      urlDomain: 'enterprise',
      participant_view: 'overview',
      domain_label: 'Enterprise'
    },
    ics: {
      urlDomain: 'ics',
      participant_view: 'overview',
      domain_label: 'ICS'
    },
    managed_services: {
      urlDomain: 'managed-services',
      participant_view: 'managed-services',
      domain_label: 'Managed Services'
    },
    deceptions: {
      urlDomain: 'trials-deceptions',
      participant_view: 'deceptions',
      domain_label: 'Evaluations Trials: Deceptions'
    }
  };
  return domainToUrlMap[domain.toLowerCase()];
}

export function getDomainFromURL(url) {
  let urlToDomainMap = {
    enterprise: {
      domain: 'ENTERPRISE',
      participant_view: 'overview',
      domain_label: 'Enterprise'
    },
    ics: {
      domain: 'ICS',
      participant_view: 'overview',
      domain_label: 'ICS'
    },
    'managed-services': {
      domain: 'MANAGED_SERVICES',
      participant_view: 'managed-services',
      domain_label: 'Managed Services'
    },
    'trials-deceptions': {
      domain: 'DECEPTIONS',
      participant_view: 'deceptions',
      domain_label: 'Evaluations Trials: Deceptions'
    }
  };
  return urlToDomainMap[url.toLowerCase()]
}

export function getDatePublished(adversary, currentYear) {
  return adversary.date_published ? adversary.date_published.slice(0, 4) : currentYear;
}

export function getParticipantResultsRoute(adversary, participant) {
  let scenarios = [...adversary.scenarios];
  scenarios = scenarios.sort((a, b) => a.number - b.number);
  let selectedScenario = scenarios[0];
  if(adversary.name === "er6" && !participant.participant_capabilities.includes('macOS Capability')) {
    selectedScenario = scenarios[1];
  }
  const vendorParam = `vendor=${participant.participant.name}`;
  const scenarioParam = `scenario=${selectedScenario.number}`;
  const evaluationParam = `evaluation=${adversary.name}`;
  const viewParam = "view=individualParticipant";
  const domain = getURLFromDomain(adversary.domain).urlDomain;
  return `/results/${domain}/?${vendorParam}&${scenarioParam}&${evaluationParam}&${viewParam}`;
}